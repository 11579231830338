import { DEFAULT_LANGUAGE } from './feature-flags';
/**
 * ************************************************
 * Time & Language Property
 * ************************************************
 */
import dayjs from 'dayjs';
import 'dayjs/locale/id';
import 'dayjs/locale/en';

/**
 * @constants
 * for Brides information
 */
export const IS_BOY_FIRST = true; //change to false for switch broadcast text

/*Girl Profile*/
export const GIRL_NAME = 'Inez Valencia';
export const GIRL_NAME_SHORT = 'Inez';
export const GIRL_FATHER_NAME = `Kuntjoro Tedjo`;
export const GIRL_MOTHER_NAME = `Myra Marina Jusri`;

/*Boy Profile*/
export const BOY_NAME = 'Georgius Jonathan Tanusantoso';
export const BOY_NAME_SHORT = 'Gie';
export const BOY_FATHER_NAME = `Markus Hardjono Tanusantoso`;
export const BOY_MOTHER_NAME = `Djulianti Djajadi`;


export const GIRL_PARENT_NAME = 
  DEFAULT_LANGUAGE === 'en'
    ? `The Daughter of <b>Mr. ${GIRL_FATHER_NAME}</b> and <b>Mrs. ${GIRL_MOTHER_NAME}</b>.`
    : `Perempuan yang lebih kerap disapa <b>${GIRL_NAME_SHORT}</b> ini adalah putri kedua dari pasangan <b>Bapak ${GIRL_FATHER_NAME}</b> dan <b>Ibu ${GIRL_MOTHER_NAME}</b>. Lahir di kota Semarang, kini ${GIRL_NAME_SHORT} telah menemukan labuhan hati terakhirnya, ${BOY_NAME_SHORT}.`;

export const BOY_PARENT_NAME =
  DEFAULT_LANGUAGE === 'en'
    ? `The Son of <b>Mr. ${BOY_FATHER_NAME}</b> and <b>Mrs. ${BOY_MOTHER_NAME}</b>.`
    : `<b>${BOY_NAME_SHORT}</b>, begitulah Ia disapa. Putra dari pasangan <b>Bapak ${BOY_FATHER_NAME}</b> dan <b>Ibu ${BOY_MOTHER_NAME}</b> ini lahir di kota Semarang. Setelah mengagumi ${GIRL_NAME_SHORT} sejak lama, akhirnya kini Ia berhasil mendapatkan hati ${GIRL_NAME_SHORT} untuk selamanya.`;

export const THE_BRIDE = IS_BOY_FIRST
  ? `${BOY_NAME_SHORT} & ${GIRL_NAME_SHORT}` 
  : `${GIRL_NAME_SHORT} & ${BOY_NAME_SHORT}`;

/**
 * ************************************************
 * Instagram Profile Account
 * @important please put instagram id without `@`
 * ************************************************
 */
export const IG_BOY = 'georgiusjon';
export const IG_GIRL = 'itdjo';

/**
 * ************************************************
 * SEO Requirement
 * @important - Don't forget to update SEO IMAGE
 * ************************************************
 */
export const SEO_IMAGE = `https://ik.imagekit.io/farahoktarina/GieInez/seo_joX8hCeia?updatedAt=1703127946064`;
export const SEO_URL = 'https://invitato.id/GieInez';
export const SEO_TITLE = `The Wedding of ${THE_BRIDE} by Invitato.id`;
export const SEO_DESCRIPTION =
  DEFAULT_LANGUAGE === 'en'
    ? `Together with joyful hearts, we are pleased to announce the beginning of this new chapter of our lives together. Please click the Website Invitation link on this message for more information about our wedding details:`
    : `Menjadi sebuah kebahagiaan bagi kami untuk mengumumkan awal dari babak baru kehidupan kami bersama. Silakan klik tautan situs Undangan Website di bawah untuk informasi lebih lanjut:`;

/**
 * ************************************************
 * Time requirement for Counting Down, and
 * Remind me generator
 * @important - please convert time to Epoch time by
 * using this link https://www.epochconverter.com/
 * ************************************************
 */
export const EPOCH_START_EVENT = 1708747200;
export const EPOCH_RESEPSI_START = 1708747200;
export const EPOCH_END_EVENT = 1708786800;

/**
 * ************************************************
 * DATE REQUIREMENT
 * @important - Format date YYYY-MM-DD
 * ************************************************
 */
export const DATE_AKAD = new Date('2024-02-24');
export const DATE_RESEPSI = new Date('2024-02-24');

export const WEDDING_DATE_DAY = dayjs(DATE_AKAD).format('dddd');
export const WEDDING_DATE_DATE = dayjs(DATE_AKAD).format('DD MMMM YYYY');
export const WEDDING_AKAD = dayjs(DATE_AKAD).locale(DEFAULT_LANGUAGE).format('dddd, DD MMMM YYYY');
export const WEDDING_AKAD_TIME = '11.00 WIB';
export const WEDDING_LOCATION_AKAD = 'Amaryllis Boutique Resort - Chapel';
export const WEDDING_LOCATION_AKAD_ROAD = `Jl. Raya Taman Safari, Tugu Selatan, Kec. Cisarua, Kabupaten Bogor, Jawa Barat 16750`;
export const WEDDING_LOCATION_AKAD_ROAD_SIMPLE = `Kabupaten Bogor, Jawa Barat`;

export const WEDDING_RESEPSI_DAY = dayjs(DATE_RESEPSI).format('dddd');
export const WEDDING_RESEPSI = WEDDING_DATE_DATE;
export const WEDDING_RESEPSI_TIME = '18.00 WIB';
export const WEDDING_LOCATION = 'Amaryllis Boutique Resort';
export const WEDDING_RESEPSI_CITY = 'Bogor';
export const WEDDING_LOCATION_ROAD = 'Jl. Raya Taman Safari, Tugu Selatan, Kec. Cisarua, Kabupaten Bogor, Jawa Barat 16750';
export const WEDDING_DATE = dayjs(DATE_RESEPSI).format('DD • MM • YYYY');

export const WEDDING_DRESSCODE = 'Semi Formal Pastel Colors'
export const AKAD_NOTE = 'No Sage Green, No jeans, No Stilettos'
export const WEDDING_NOTE = 'No Dusty Blue, No Jeans, No Stiletto (Garden Party) '
/**
 * ********************************************************
 * Link Generator V2
 * @important - this info will be applied at link generator
 * ********************************************************
 */
export const HOSTNAME = 'https://GienezRecords.com/';
export const BROADCAST_WEDDING_LOCATION = WEDDING_LOCATION;
export const BROADCAST_WEDDING_DAY = {
  id: dayjs(DATE_RESEPSI).locale('id').format('dddd, DD MMM YYYY'),
  en: dayjs(DATE_RESEPSI).locale('en').format('dddd, DD MMM YYYY'),
};

/**
 * ************************************************
 * Maps Location
 * ************************************************
 */
export const GOOGLE_MAPS_LINK = `https://maps.app.goo.gl/SDjrHdWuyzziwQsS6?g_st=iw`;
export const GOOGLE_MAPS_ADDRESS = WEDDING_LOCATION;

/**
 * ************************************************
 * Backsound Copyright
 * ************************************************
 */
export const SOUND_BY = `Tip Toe - HYBS`;
export const SOUND_URL = 'https://youtu.be/dHUq9xJcaZs?si=ciVEDkQ1ojxIdKZj';

/**
 * ************************************************
 *  Youtube Live requirement
 * ************************************************
 */
export const YOUTUBE_LINK = 'https://www.youtube.com/live/Q6sM5KWuh-E?si=ZGVpumz51983tQca';
export const YOUTUBE_EMBED = 'https://www.youtube.com/embed/Q6sM5KWuh-E?si=ZGVpumz51983tQca';
export const PREWEDDING_LINK = 'https://youtu.be/Ah0MmioLcRY?si=kM38G6t962p0-1O6';
export const PREWEDDING_EMBED = 'https://www.youtube.com/embed/Ah0MmioLcRY?si=tiuWdP0CkRwFRMRI';

/**
 * ************************************************
 *  RSVP & Wishlish Section
 * ************************************************
 */
export const API_HOSTNAME = `https://script.google.com/macros/s/AKfycbxjaO8TO6V0dDYdYQx4WqxPOqH0PLywGT4MMI_b7oOkWkyRdXTkhA1iJJ6lLYRNPlMD4Q/exec`;
export const API_TABLE_NAME = 'wishlist';
export const API_RSVP_TABLE = 'rsvp';
export const SHEET_GIFT_CONFIRMATION = 'gift_confirmation';

/**
 * ************************************************
 *  Invitato Link Requirement
 * ************************************************
 */
export const URL_INVITATO = 'https://invitato.net';
export const URL_IG_INVITATO = 'https://www.instagram.com/invitato.id/';
export const URL_WA_INVITATO = `https://wa.me/+6281329274582?text=Hi%20Kak,%20aku%20mau%20pesan%20undangan%20online%20nih!`;
export const INVITATO_URL = `https://invitato.id`;